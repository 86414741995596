<template>
    <div>
        <div class="header" id="header">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组763_1686894590711.png" class="hwlogo" @click="toindex">
            <div class="headericon" id="headericon" @click="headericon"></div>
        </div>
        <div class="headerhidden" id="headerhidden">
            <div class="headeritem" @click="toindex"> <span> 首页 </span> </div>
            <div class="headeritem" v-for="(item) in columnData" :key="item.id" @click="toLink(item.url)">
                <span>{{item.columnName}}</span>
            </div>
        </div>
        <div style="height: 1.08rem;"></div>


        
        <div class="hzjgBox" v-if="companyDetail">
            <div class="hzjgItem">
                <img :src="companyDetail.sourceAvatar" style="display:block;margin:0 auto 0.2rem;width:2.94rem;height:auto;">
                <p class="hzjgItemName">{{companyDetail.fullTitle}}</p>
                <p class="hzjgItemInfo" v-html="companyDetail.content">

                </p>
                <!-- <div class="hzjgItemMore">
                    <span @click="hzjgItemMore(0)">展开</span>
                </div>
                <div class="hzjgItemhide hzjgItemhideHide">
                    <span @click="hzjgItemHide(0)">收起</span>
                </div> -->

                
            </div>



        </div>


        <Foot />
        <div class="toGrzx" @click="toGrzx">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/个人中心(1)(1)_1686566476728.png" class="toGrzxImg">
        </div>


    </div>
</template>



<script>
import Foot from "@/components/foot.vue"
import Cookies from 'js-cookie'
import {getListColumn,getMovieArticleById} from "@/api/index"
import store from '@/store'
import { Loading ,Message } from 'element-ui';
export default {
    components:{
        Foot
    },
    data () {
        return {
            headerflag : false,
            hiddenflag : false,
            articlelist:[
            ],
            total:null,
            pageSize:8,
            currPageNo:1,
            articleload:true,
            t : null,
            clientHeight : null,
            scrollHeight : null,
            load : null,
            value1:"",
            columnData:null,
            companyDetail:{
                "sourceAvatar":"",
                "fullTitle":"",
                "content":""
            },
        }
    },
    computed: {
        columnList() {
            return this.$store.getters.columnList
        },
    },
    methods:{
        toGrzx(){
            if(!Cookies.get('wjsToken')){
                this.$router.push({name:'dl'});
            }else{
                this.$router.push({name:'grzx'});
            }
        },
        toLink(name){
            //if(name == "yszb"){
                //window.location.href="http://www.zygart.cn"; 
            //}else{
                this.reset()
                this.$router.push({name:name});
            //}
        },
        toBack(){
            this.reset()
            // if(this.$route.params.linkType && this.$route.params.linkType == 1){
            //     this.$router.push({name:'index'});
            // }else if(this.$route.params.linkType && this.$route.params.linkType == 2){
            //     this.$router.push({name:'movielist'});
            // }else{
            //     this.$router.push({name:'index'});
            // }
            this.$router.back(-1)
        },
        toyszb(){
            this.reset()
            this.$router.push({name:'yszb'});
        },
        tojrpf(){
            this.reset()
            this.$router.push({name:'jrpf'});
        },
        tohzjg(){
            this.reset()
            this.$router.push({name:'hzjg'});
        },
        tobsxw(){
            this.reset()
            this.$router.push({name:'bsxw'});
        },
        tozcfg(){
            this.reset()
            this.$router.push({name:'zcfg'});
        },
        toindex(){
            this.reset()
            this.$router.push({name:'index'});
        },
        toaboutus(){
            this.reset()
            this.$router.push({name:'gywm'});
        },
        tonews(){
            this.reset()
            this.$router.push({name:'hydt'});
        },
        tonews2(){
            this.reset()
            document.body.scrollTop = document.documentElement.scrollTop = 0
            this.$router.push({name:'news'});
        },
        togsdt(){
            this.reset()
            this.$router.push({name:'gsdt'});
        },
        toyszx(){
            this.reset()
            this.$router.push({name:'yszx'});
        },
        tohyfx(){
            this.reset()
            this.$router.push({name:'hyfx'});
        },
        tozcjd(){
            this.reset()
            this.$router.push({name:'zcjd'});
        },
        tomovielist(){
            this.reset()
            this.$router.push({name:'xmpl'});
        },
        tongyhd(){
            this.reset()
            this.$router.push({name:'gyhd'});
        },
        touserguide(){
            this.reset()
            this.$router.push({name:'userGuide'});
        },
        tocu(){
            this.reset()
            this.$router.push({name:'connectus'});
        },
        headericon(){
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            if(this.headerflag){
                headerhidden.style.top = "-100%"
                headericon.classList.remove("headericon2")
                headericon.classList.add("headericon")
                this.headerflag = false
            }else{
                headerhidden.style.top = "1.06rem"
                headericon.classList.remove("headericon")
                headericon.classList.add("headericon2")
                this.headerflag = true
            }
        },
        headjt(){
            var headjt = document.getElementById("headjt")
            var hiddenbox = document.getElementById("hiddenbox")
            if(this.hiddenflag){
                hiddenbox.style.height = "0"
                headjt.style.transform = "rotateZ(0deg)"
                this.hiddenflag = false
            }else{
                hiddenbox.style.height = "4rem"
                headjt.style.transform = "rotateZ(-180deg)"
                this.hiddenflag = true
            }
        },
        reset(){
        this.headerflag = false
        this.hiddenflag = false
        var headericon = document.getElementById("headericon")
        var headerhidden = document.getElementById("headerhidden")
        //var headjt = document.getElementById("headjt")
        //var hiddenbox = document.getElementById("hiddenbox")
        headerhidden.style.top = "-100%"
        headericon.classList.remove("headericon2")
        headericon.classList.add("headericon")
        //hiddenbox.style.height = "0"
        //headjt.style.transform = "rotateZ(0deg)"
    },
        windowscroll(){
            this.t = document.documentElement.scrollTop || document.body.scrollTop;
            this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            var url = this.$route.path

            if(this.scrollHeight > this.clientHeight && this.t + this.clientHeight + 400  >= this.scrollHeight) {
                // if(this.articleload && this.articlelist.length && this.articlelist.length < this.total){
                //     if(!this.$route.query.articleId){
                //         if(url.indexOf("yszx") != -1){
                //             this.showLoading()
                //             this.articleload = false;
                //             this.currPageNo += 1;
                //             listArticle({
                //                 "data": {
                //                     "categoryId": 3,
                //                 },
                //                 "mapParam": {},
                //                 "reqPage": {
                //                     "currPageNo": this.currPageNo,
                //                     "pageSize": this.pageSize
                //                 }
                //             }).then(res => {
                //                 this.articlelist = this.articlelist.concat(res.records)
                //                 this.load && this.load.close();
                //             })

                //             setTimeout(() => {
                //                 this.articleload = true;
                //             },1000)

                //         }
                //     }
                    
                // }
                
            }
        },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        hzjgItemMore(index){
            document.getElementsByClassName('hzjgItemMore')[index].classList = "hzjgItemMore hzjgItemMoreHide";
            document.getElementsByClassName('hzjgItemInfo')[index].classList = "hzjgItemInfo";
            document.getElementsByClassName('hzjgItemhide')[index].classList = "hzjgItemhide";

        },
        hzjgItemHide(index){
            document.getElementsByClassName('hzjgItemMore')[index].classList = "hzjgItemMore";
            document.getElementsByClassName('hzjgItemInfo')[index].classList = "hzjgItemInfo hzjgItemInfoHide";
            document.getElementsByClassName('hzjgItemhide')[index].classList = "hzjgItemhide hzjgItemhideHide";


        },
    },
    mounted(){
        
        this.showLoading()

        // let head = document.getElementsByTagName('head');
        // let meta = document.createElement('meta');
        // document.querySelector('meta[name="keywords"]').setAttribute('content', "新闻资讯-影视资讯-湖北引未来影视")
        // document.querySelector('meta[name="description"]').setAttribute('content', "新闻资讯-影视资讯-湖北引未来影视")
        // meta.content = {
        //     keywords:"新闻资讯-影视资讯-湖北引未来影视",
        //     description:"新闻资讯-影视资讯-湖北引未来影视",
        // };
        // head[0].appendChild(meta)
        // document.title = "新闻资讯-影视资讯-湖北引未来影视";


        this.t = document.documentElement.scrollTop || document.body.scrollTop;
        this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        window.addEventListener('scroll',this.windowscroll)

        if(this.columnList){
            console.log("store" + this.columnList)
            this.columnData = this.columnList;
        }else{
            getListColumn().then(res => {
                //console.log(res)
                this.columnData = res.result;

                store.dispatch('SetColumnList', res.result)



            })
        }

        getMovieArticleById({
                "id": '1662062404421660673',
        }).then(res => {
            console.log(res)
            // this.articlelist = res.result.records;
            // this.total = res.result.total;
            this.companyDetail = res.result;
            this.load && this.load.close();

        })

        // listArticle({
        //     "data": {
        //         "categoryId": 3,
        //     },
        //     "mapParam": {},
        //     "reqPage": {
        //         "currPageNo": this.currPageNo,
        //         "pageSize": this.pageSize
        //     }
        // }).then(res => {
        //     this.articlelist = res.records
        //     this.total = res.total
            //this.load && this.load.close();
        //})


    },
    beforeDestroy (){
        window.removeEventListener('scroll',this.windowscroll)
    },
    filters:{
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },
}
</script>




<style lang="scss">

.hzjgBox{
    width: 100%;
    padding: 0.16rem 0.22rem;
    box-sizing: border-box;
    background: #F6F6F6;
    .hzjgItem{
        padding: 0.38rem 0.32rem 0.2rem;
        box-sizing: border-box;
        position: relative;
        background: #FFFFFF;
        overflow: hidden;
        margin-bottom: .14rem;
        
        .hzjgItemName{
            font-size: .3rem;
            font-weight: bold;
            color: #343434;
            margin-bottom: .24rem;
            text-align: center;
        }
        .hzjgItemInfo{
            font-size: .26rem;
            font-weight: 400;
            color: #343434;
            line-height: .36rem;
            text-align: justify;
            margin-bottom: .24rem;
            video{
                width: 100% !important;
                height: auto !important;
            }
        }
        .hzjgItemInfoHide{
            display: -webkit-box;    
            -webkit-box-orient: vertical;    
            -webkit-line-clamp: 5;    
            overflow: hidden;
        }
        .hzjgItemMore{
            font-size: .26rem;
            font-weight: 400;
            color: #AE1F20;
            line-height: .36rem;
            text-align: right;
            margin-bottom: .24rem;
        }
        .hzjgItemMoreHide{
            display: none;
        }
        .hzjgItemhide{
            font-size: .26rem;
            font-weight: 400;
            color: #AE1F20;
            line-height: .36rem;
            text-align: right;
            margin-bottom: .24rem;
        }
        .hzjgItemhideHide{
            display: none;
        }
    }
}
</style>